<!-- <div class="cover">
  <div class="video-thumbnail"></div>
</div> -->
<ng-container *ngIf="!showSimpleHeader;else simpleHeader">
  <ng-container *ngIf="isDesktop; else mobileNavBar">
    <div class='fluid-header shifter-header'>
      <div class="topNavItem">
        <div id="component" class="container">
          <div class="d-flex justify-content-end align-items-center">
            <div class="search-btn">
              <a>
                <img class="search-icon" src="assets/img/search-icon.svg" alt="">
                {{"topNav.topItem1" | translate}}</a>
            </div>

            <div class="partner-portal-login" [routerLink]="'/home'">
              <a [href]="partnerPortalUrl" target="_blank"> {{"topNav.topItem2" |
                translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid nav-padding" style="height: 100px">
          <div class="container mainNav d-flex justify-content-between align-items-center p-0">
            <a class="navbar-brand m-0 p-0" href="http://www.tesvolt.com"><span class="logo"><img
                  src="assets/tesvolt-logo.svg" alt="Logo"></span></a>
            <div class="position-relative">
              <ul class="position-relative navbar-nav d-flex flex-row align-items-center">
                <!-- <li class="nav-item nav-icons">
              <a class="nav-link">
                  <i class="fa fa-sliders" aria-hidden="true" style="font-size: x-large;"></i>
              </a>
          </li> -->
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/products.html" target="_blank"
                    aria-current="page">{{"topNav.item1" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/applications.html" target="_blank"
                    aria-current="page">{{"topNav.item2" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/partner.html" target="_blank"
                    aria-current="page">{{"topNav.item3" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/service.html" target="_blank"
                    aria-current="page">{{"topNav.item4" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/company.html" target="_blank"
                    aria-current="page">{{"topNav.item5" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/projects/" target="_blank"
                    aria-current="page">{{"topNav.item6" | translate}}</a>
                </li>

                <li class="nav-item position-relative nav-icons"><a data-bs-toggle="dropdown" class="nav-link">
                    <img src="assets/germany.svg" *ngIf="selectedLanguage == 'language.german'" height="30"
                      width="30" />
                    <img src="assets/united-kingdom.svg" *ngIf="selectedLanguage == 'language.english'" height="30"
                      width="30" />
                    <!-- <img src="assets/italy.svg" *ngIf="selectedLanguage == 'language.italian'" height="30" width="30" />
                  <img src="assets/dutch.svg" *ngIf="selectedLanguage == 'language.dutch'" height="30" width="30" /> -->
                  </a>
                  <div class="dropdown-menu dropdown-custom-menu dropdown-menu-small lang-dd"
                    aria-labelledby="dropdownMenuButton">
                    <!-- <a (click)="selectLanguage('en')" class="dropdown-item"><img src="assets/united-kingdom.svg" height="30"
                      width="30" />&nbsp; {{'English' |
                    uppercase}}</a> -->
                    <a (click)="selectLanguage('de')" class="dropdown-item"><img src="assets/germany.svg" height="30"
                        width="30" />&nbsp;{{'Deutsch' | uppercase}}
                    </a>
                    <!-- <a (click)="selectLanguage('it')" class="dropdown-item"><img src="assets/italy.svg" height="30"
                      width="30" /> {{'Italian' |
                    uppercase}}</a>
                  <a (click)="selectLanguage('nl')" class="dropdown-item"><img src="assets/dutch.svg" height="30"
                      width="30" /> {{'dutch' |
                    uppercase}}</a> -->
                  </div>
                </li>

              </ul>
            </div>


          </div>
        </div>
      </nav>
    </div>
  </ng-container>
  <ng-template #mobileNavBar>
    <div class='fluid-header shifter-header'>
      <div class="topNavItem">
        <div id="component" class="container">
          <div class="d-flex justify-content-end align-items-center">
            <div class="search-btn">
              <a>
                <img class="search-icon" src="assets/img/search-icon.svg" alt="">
                {{"topNav.topItem1" | translate}}</a>
            </div>

            <div class="partner-portal-login" [routerLink]="'/home'">
              <a class="" href="https://tesvolttest.powerappsportals.com/" target="_blank"> {{"topNav.topItem2" |
                translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid nav-padding mob-nav-div-wrapper">
        <div class="container mobileNav d-flex justify-content-between align-items-center p-0">
          <a class="navbar-brand m-0 p-0" href="http://www.tesvolt.com">
            <span class="logo">
              <img src="assets/tesvolt-logo.svg" alt="Logo">
            </span>
          </a>
          <div class="position-relative">
            <ul class="position-relative navbar-nav d-flex flex-row align-items-center">
              <div class="desktop-navItems position-relative navbar-nav d-flex flex-row align-items-center"
                id="mySidenav" class="sidenav" [style.width.px]="sidenavWidth" [style.z-index]="sideNavZindex">
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/products.html" target="_blank"
                    aria-current="page">{{"topNav.item1" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/applications.html" target="_blank"
                    aria-current="page">{{"topNav.item2" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/partner.html" target="_blank"
                    aria-current="page">{{"topNav.item3" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/service.html" target="_blank"
                    aria-current="page">{{"topNav.item4" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/company.html" target="_blank"
                    aria-current="page">{{"topNav.item5" | translate}}</a>
                </li>
                <li class="nav-item text-uppercase">
                  <a class="nav-link" href="https://www.tesvolt.com/en/projects/" target="_blank"
                    aria-current="page">{{"topNav.item6" | translate}}</a>
                </li>
              </div>
              <li class="nav-item position-relative nav-icons"><a data-bs-toggle="dropdown" class="nav-link">
                  <img src="assets/germany.svg" *ngIf="selectedLanguage == 'language.german'" height="30" width="30" />
                  <img src="assets/united-kingdom.svg" *ngIf="selectedLanguage == 'language.english'" height="30"
                    width="30" />
                </a>
                <div class="dropdown-menu dropdown-custom-menu dropdown-menu-small lang-dd"
                  aria-labelledby="dropdownMenuButton">

                  <a (click)="selectLanguage('de')" class="dropdown-item"><img src="assets/germany.svg" height="30"
                      width="30" />&nbsp;{{'Deutsch' | uppercase}}
                  </a>
                </div>
              </li>
              <li (click)="toggleNav()">
                <img class="hamburger-icon" src="assets/hambuger.svg" alt="">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </ng-template>
</ng-container>
<ng-template #simpleHeader>
  <div class='header'>
    <div class="logo-section">
      <a class="logo"><span class="logo"><img src="assets/TesvoltLogo.png" alt="Logo" [routerLink]="'/ees'"></span></a>
    </div>
  </div>
</ng-template>